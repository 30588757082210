import { Component, Vue, Prop } from 'vue-property-decorator';
import IconUser from '@/assets/icons/user.svg?inline';
import { AccountButtonViewModel } from './AccountButton.types';
import { getComponentProps } from '@/utils/vueUtils';

const AccountButtonProps = Vue.extend({
  props: getComponentProps<AccountButtonViewModel>({
    IsLoggedIn: false,
    LoginLink: '/login',
    AccountLink: '/my-account',
  }),
});

@Component({
  components: {
    IconUser,
  },
})
export default class AccountButton extends AccountButtonProps {
  get link(): string {
    return this.p.IsLoggedIn ? this.p.AccountLink : this.p.LoginLink;
  }

  get message(): string {
    return this.p.IsLoggedIn ? 'My Account' : 'Sign In';
  }

  fixTooltip() {
    const link = this.$refs.signin as HTMLLinkElement
    if (!link.title) link.title = this.message
  }
}
