import { Component, Vue, Watch } from 'vue-property-decorator';
import LargeSearchBar from '@/components/LargeSearchBar/LargeSearchBar.vue';
import SearchSuggestions from '@/components/SearchSuggestions/SearchSuggestions.vue';
import { Getter } from 'vuex-class';
import { SM, MD } from '@/utils/breakpointUtils';
import SizeBreakpoints from '@/common/interfaces/SizeBreakpoints';
import IconArrow from '@/assets/icons/arrow.svg?inline';
import SearchPreviewCard from '@/components/SearchPreviewCard/SearchPreviewCard.vue';
import SearchResultsCategory from '@/modules/SearchModule/SearchModule.types';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { NavigationSearchViewModel } from './NavigationSearch.types';
import { SearchHints, SearchSegment, SearchSegmentModel } from '../NavigationModule.api';

import debounce from 'lodash/debounce';
import { SegmentElement } from '@/plugins/utm/SegmentElement';
import { getStore } from '@/store';
const NavigationSearchProps = Vue.extend({
  props: {
    model: Object as () => NavigationSearchViewModel,
    expand: Boolean,
  },
});

@Component({
  components: {
    LargeSearchBar,
    SearchSuggestions,
    IconArrow,
    SearchPreviewCard,
  },
})
export default class NavigationSearch extends NavigationSearchProps {
  tempScrollPos = 0;

  modalIsActive = false;
  searchHasFocus = false;
  showFinder = false;

  pushSearchValue = null;
  isValidSearchQuery = false;
  searchResults = [];
  searchStr = '';
  debouncedHandleSearch = debounce(this.handleSearchQueryValidation, 500);

  segmentAnalyticsData: Array<SearchSegmentModel> = [];
  module: HTMLElement;
  globalAnalytics: any = {};

  setModalActive(setVal) {
    let teamfinder = <HTMLElement>this.$refs.teamfinder;
    let searchResults = <HTMLElement>this.$refs.results;

    if (setVal === true && this.modalIsActive === false) {
      this.tempScrollPos =
        teamfinder.getBoundingClientRect().top -
        document.documentElement.getBoundingClientRect().top;
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      if (!/iPad|iPhone|iPod/.test(navigator.platform) && screen.width >= MD) {
        document.documentElement.classList.add('no-scroll', 'h-100');
        document.body.classList.add('no-scroll', 'h-100');
        document.getElementById('app').classList.add('h-100');
        disableBodyScroll(searchResults);
      }
      this.modalIsActive = true;
    } else if (setVal === false && this.modalIsActive === true) {
      document.documentElement.classList.remove('no-scroll', 'h-100');
      document.body.classList.remove('no-scroll', 'h-100');
      document.getElementById('app').classList.remove('h-100');

      if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
        enableBodyScroll(searchResults);
      }
      window.scrollTo(0, Math.max(window.pageYOffset, this.tempScrollPos));
      this.modalIsActive = false;
    }
  }

  handleSearchFocus(isFocus, evt) {
    this.searchHasFocus = this.isValidSearchQuery ? true : isFocus;
    this.pushSearchValue = this.isValidSearchQuery ? this.pushSearchValue : null;
    this.showFinder = false;

    if (document.querySelector('.is-search-active')) {
      this.searchHasFocus = true;
    }
  }

  handleSearchInput(input) {
    const oldVal = this.pushSearchValue;
    this.pushSearchValue = input;
    if (this.pushSearchValue === oldVal) {
      this.handleSearchQueryValidation(this.pushSearchValue, oldVal);
    }
  }

  handleSuggestionSelected(data) {
    document.location.href = `/search?q=${data
      .trimEnd()
      .trimStart()
      .replace(/\s+/g, '+')}`;
  }

  finderToggle(value) {
    if (this.showFinder !== value) {
      if (window.innerWidth <= SM) this.setModalActive(value);
      this.showFinder = value;
    }
  }

  @Getter isInBreakpoint!: (breakpoints: SizeBreakpoints[] | SizeBreakpoints) => boolean;
  get isMobileWidth() {
    return this.isInBreakpoint(['xs', 'sm', 'md']);
  }

  @Watch('pushSearchValue')
  some(newVal: string, oldVal: string) {
    //console.log('debounce');
    this.debouncedHandleSearch(newVal, oldVal);
  }

  async handleSearchQueryValidation(newVal: string, oldVal: string) {
    this.isValidSearchQuery = newVal && newVal.length > 2 ? true : false;
    if (this.isValidSearchQuery) {
      //API call
      this.searchStr = newVal;
      if (this.expand) {
        this.searchResults = await SearchHints(newVal, false, SearchResultsCategory.Itineraries);
        SegmentElement.sendEvent('productsSearched', { query: newVal, ...this.getSegmentProps() });
        const urls: string[] = this.searchResults.map(r => r.Link);
        if (urls.length) {
          const res: any = await SearchSegment(urls);
          this.segmentAnalyticsData = res;
        }
        if (!this.searchResults) this.searchResults = [];
      } else {
        this.$emit('new-search', newVal);
      }
    } else {
      this.searchResults = [];
    }
  }

  productClicked({ url, position }) {
    const productAnalyticsData = this.segmentAnalyticsData.find(el => el.url === url);
    productAnalyticsData.categories = productAnalyticsData.categories.map(category => ({ category }));

    const productBusinessData = this.searchResults.find(el => el.Link === url);
    const image_url = productBusinessData.Image.Url;

    const props = {
      ...productAnalyticsData, position, image_url,
      ...this.getSegmentProps()
    }
    SegmentElement.sendEvent('productClicked', props);
  }

  getSegmentProps() {
    return {
      module_type_id: this.module.getAttribute('data-segmentid'),
      module_type_name: this.module.getAttribute('type'),
      page_type_id: this.globalAnalytics.page_type_id,
      page_type_name: this.globalAnalytics.page_type_name,
    }
  }

  onSearchMaskClick() {
    this.$emit('searchClickout')
  }

  clearSearch() {
    this.searchHasFocus = false;
    this.pushSearchValue = null;
  }

  mounted() {
    if (this.model.SearchStr) this.searchStr = this.model.SearchStr;
    if (document.querySelector('.is-search-active')) {
      this.searchHasFocus = true;
    }
    this.module = this.$el.closest('*[contentlinkid]');
    this.globalAnalytics = getStore().state.Meta.Analytics;
  }

  beforeDestroy() {
    let searchResults = <HTMLElement>this.$refs.results;
    if (!/iPad|iPhone|iPod/.test(navigator.platform)) {
      enableBodyScroll(searchResults);
    }
  }
}
