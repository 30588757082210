import { Component, Vue, Prop } from 'vue-property-decorator';
import { headroom } from 'vue-headroom';
import { Getter } from 'vuex-class';

import { HAS_ANCHOR_BAR } from '@/store/getters';

import AnnouncementRibbon from "@/components/AnnouncementRibbon/AnnouncementRibbon.vue";
import AccountButton from '@/components/AccountButton/AccountButton.vue';
import NavigationTop from '@/components/NavigationTop/NavigationTop.vue';
import NavigationCard from '@/components/NavigationCard/NavigationCard.vue';
import NavigationList from '@/components/NavigationList/NavigationList.vue';
import NavigationSlider from '@/components/NavigationSlider/NavigationSlider.vue';
import NavigationText from '@/components/NavigationText/NavigationText.vue';
import NavigationSearch from './NavigationSearch/NavigationSearch.vue';
import LogoLindbladExpeditionsLarge from '@/assets/icons/logo-lindblad-expeditions-large.svg?inline';
import LogoLindbladExpeditionsSmall from '@/assets/icons/logo-lindblad-expeditions-small.svg?inline';
import LogoNationalGeographicLarge from '@/assets/icons/logo-national-geographic-large.svg?inline';
import LogoNationalGeographicSmall from '@/assets/icons/logo-national-geographic-small.svg?inline';
import LogoWorldOfHyatt from '@/assets/icons/logo-world-of-hyatt.svg?inline';
import IconArrow from '@/assets/icons/arrow.svg?inline';
import IconBurger from '@/assets/icons/burger.svg?inline';
import IconCross from '@/assets/icons/cross.svg?inline';
import IconPhone from '@/assets/icons/phone.svg?inline';
import IconSearch from '@/assets/icons/search.svg?inline';
import IconHeart from '@/assets/icons/heart.svg?inline';
import { NavigationViewModel } from './NavigationModule.types';
import { getComponentProps } from '@/utils/vueUtils';
import { BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';
import NavigationBackground from '@/common/interfaces/enums/navigationEnums/NavigationBackground';
import NavigationShowIn from '@/common/interfaces/enums/navigationEnums/NavigationShowIn';
import NavigationSizes from '@/common/interfaces/enums/navigationEnums/NavigationCardSizes';

import Chevron from '@/assets/icons/chevron.svg?inline';
import { getStore } from '@/store';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

const NavigationProps = Vue.extend({
  props: getComponentProps<NavigationViewModel>({
    ShowNGLogo: true,
    IsWhite: false,
    TopData: {
      NumberStr: 'Call 1.800.397.3348',
      Number: '1.800.397.3348',
      Links: [
        {
          Title: 'Order brochure',
          Link: '/test',
        },
      ],
    },
    AccountModel: {
      IsLoggedIn: false,
      LoginLink: '/login',
      AccountLink: '/my-account',
    },
    SearchData: {
      Placeholder: 'What are you looking fooooor?',
      SearchSuggestions: {
        Items: [
          {
            Title: 'Quick Links',
            //IsNarrow: true,
            Items: [
              {
                Image: {
                  Url: 'https://leximageresizer20191018085259.azurewebsites.net/images/girl.jpg',
                  Alt: '',
                },
                Title: 'Plan',
                Description: 'Itinerary Finder',
                Link: '/pages/T031ItineraryFinder',
              },
            ],
          },
          {
            Title: 'Popular Search Terms',
            //IsNarrow: false,
            Items: ['Alaska', 'Galapagos', 'National Geographic Endeavour', 'Arctic', 'Our fleet'],
          },
        ],
      },
    },
    Items: [
      {
        Title: 'Destinations',
        Items: [
          {
            Type: 'list',
            Link: { Title: '123', Link: '#' },
            Title: 'Alaska',
            BackgroundColor: NavigationBackground.Regular,
            Size: NavigationSizes.Large,
            ShowIn: NavigationShowIn.Both,
            Links: [{ Title: '123', Link: '#' }],
          },
        ],
      },
    ],
  }),
});

@Component({
  components: {
    AnnouncementRibbon,
    AccountButton,
    NavigationTop,
    NavigationCard,
    NavigationList,
    NavigationSlider,
    NavigationText,
    NavigationSearch,
    LogoLindbladExpeditionsLarge,
    LogoLindbladExpeditionsSmall,
    LogoNationalGeographicLarge,
    LogoNationalGeographicSmall,
    LogoWorldOfHyatt,
    IconArrow,
    IconBurger,
    IconCross,
    IconPhone,
    IconSearch,
    IconHeart,
    Chevron,
    headroom,
  },
})

export default class Navigation extends NavigationProps {
  @Getter(HAS_ANCHOR_BAR) hasAnchorBar!: boolean;

  isMainActive: boolean = false;
  isSearchActive: boolean = false;
  isSticky: boolean = false;
  activeSubItem: number = -1;
  activeChildren: string[] = [];
  windowWidth: number = 0;
  reIndex: boolean = true;
  isHidden: boolean = false;
  navClickScroll: number = 0;
  hasScrolledAfterNavClick: boolean = false;

  handleScroll(): void {
    if (this.isHidden) {
      if (window.pageYOffset < window.innerHeight) {
        // User is currently above anchor module.
        this.isHidden = false;
      }
      if (this.navClickScroll >= window.pageYOffset) { // If scrolled UP
        if (!this.hasScrolledAfterNavClick) { // Immediate unavoidable scroll after navbar click
          this.hasScrolledAfterNavClick = true;
        } else { // Next scroll
          setTimeout(() => {
            this.isHidden = false
            this.hasScrolledAfterNavClick = false
          }, 1000)
        }
      }
    }
  }

  get getActiveChildren(): string[] {
    return this.activeChildren;
  }

  get getUpdatedXl() {
    return 1200;
  }

  activateMain(): void {
    this.isMainActive = true;
    this.isSticky = false;
    document.body.style.overflow = 'hidden';
  }

  deactivateMain(): void {
    this.isMainActive = false;
    this.isSticky = true;
    this.activeSubItem = -1;
    this.isSearchActive = false;
    this.setMobileActiveChildren();
    document.body.style.overflow = 'auto';
  }

  activateSearch(): void {
    this.isSearchActive = true;
    this.activeSubItem = -1;
    document.body.style.overflow = 'hidden';
  }

  deactivateSearch(): void {
    this.isSearchActive = false;
    document.body.style.overflow = 'auto';
  }

  activateSub(index: number): void {
    if (this.activeSubItem !== index) {
      this.activeSubItem = index;
      this.setMobileActiveChildren();
    }
  }

  setMobileActiveChildren() {
    if (this.windowWidth < BREAKPOINTS.xl) {
      let tempArr: string[] = [];

      (function getActive(items, prefix = '') {
        items.forEach((el: any) => {
          if (el.IsOpenOnMobile === true) {
            tempArr.push(prefix.concat(el.Title));
          } else if (el.Items) {
            getActive(el.Items, el.Type);
          }
        });
      })(this.p.Items);

      this.activeChildren = tempArr;
    }
  }

  get bookLink() {
    const BookingUrl = getStore().state.Meta.BookingUrl as string || '/book/';
    return BookingUrl;
  }

  deactivateSub(): void {
    this.activeSubItem = -1;
    this.setMobileActiveChildren();
    document.body.style.overflow = 'hidden';
  }

  toggleChild(id: string): void {
    const exists = this.activeChildren.some((item) => item === id);
    //using 'some' as IE doesn't support 'includes'

    if (exists) {
      // Let's remove the item.
      this.activeChildren = this.activeChildren.filter((item) => item !== id);
    } else {
      // Let's add the item.
      this.activeChildren = [...this.activeChildren, id];

      this.$nextTick(() => {
        const name = id.startsWith('collection') ? id.split('collection')[1] : id
        const listBtn = document.querySelector(`button[aria-label="${name}"]`)
        const isOutSideViewport = listBtn.getBoundingClientRect().top > 500
        if (isOutSideViewport) setTimeout(() => { listBtn.scrollIntoView() }, 150)
      })
    }
  }

  get itemsWithSlider() {
    return this.p.Items.filter((i) => {
      if (i.Items && i.HasMobileSlider) return i;
    });
  }

  resetAll(): void {
    this.isMainActive = false;
    this.activeSubItem = -1;
    this.activeChildren = [];
    if (!this.isSearchActive) {
      document.body.style.overflow = 'auto';
    }
  }

  setCorrectViewport() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.setCorrectViewport);
    this.setCorrectViewport();

    this.$nextTick(function() {
      window.addEventListener('resize', this.handleResizeEvent);
      this.handleResizeEvent();

      this.$root.$on('clickedNavBar', (navClickScroll: number) => {
        this.navClickScroll = navClickScroll;
        this.isHidden = true;
      })
    });

    document.querySelectorAll('.navigation__lists-sub a').forEach(this.onGlobalNavigationClicked);
  }

  onGlobalNavigationClicked(link) {
    link.addEventListener('click', () => {
      const params = SegmentElement.getButtonParams(link);
      const navigation_category = link.closest('.navigation__lists-item').querySelector('.nav--link').innerText.trim()
      SegmentElement.sendEvent('globalNavigationClicked', {...params, navigation_level: 'main nav', navigation_category })
    })
  }

  destroyed() {
    window.removeEventListener('resize', this.setCorrectViewport);
    window.removeEventListener('resize', this.handleResizeEvent);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResizeEvent() {
    let newWidth = document.documentElement.clientWidth;
    this.windowWidth = newWidth;

    this.setMobileActiveChildren();
  }

  listMobileLinks(arr: any) {
    let outputArr: any[] = [];

    (function getLinks(arr) {
      arr.forEach((el: any) => {
        if (el.Link) {
          outputArr.push(el.Link);
        } else if (el.Items) {
          getLinks(el.Items);
        }
      });
    })(arr);

    return outputArr;
  }
}
